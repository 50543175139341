import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"

export const query = graphql`
  query formerMembers {
    formerMembers: allContentfulMembers(
      filter: { isCurrentMember: { eq: false } }
    ) {
      nodes {
        id
        fullName
        jobTitle
        email
        isCurrentMember
        curriculum {
          json
        }
        picture {
          fluid {
            src
          }
        }
      }
    }
  }
`

const FormerMembers = ({ data }) => {
  return (
    <Layout>
      <div className="container container--min-h">
        <div className="section">
          <h1 className="title is-1 mb-6">Former members</h1>
          <ul className="content">
            {data.formerMembers.nodes.map(node => (
              <li className="box">
                <h5 className="title is-5">{node.fullName}</h5>
                <div className="content">
                  {documentToReactComponents(node.curriculum?.json)}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default FormerMembers
